import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Rppc1 from "../images/rppc1.png";
import Rppc2 from "../images/rppc2.png";
import Rppc3 from "../images/rppc3.png";

const useStyles = makeStyles((theme) => ({
	sectionHeader: {
		marginBottom: 10,
	},
	imageBar: {
		marginRight: 10,
		marginLeft: 10,
		marginBottom: 40,
	},
	img: {
		maxWidth: 600,
	},
	imgSmall: {
		maxWidth: 300,
	},
	imgDiv: {
		textAlign: "center",
	},
}));

export default function ImageBar() {
	const styles = useStyles();
	return (
		<div className={styles.imageBar}>
			<Typography
				variant="h2"
				align="center"
				className={styles.sectionHeader}
			>
				Welcome to Retro Pixel Poker Club!
			</Typography>
			<Hidden xsDown>
				<Grid
					container
					spacing={2}
					justifyContent="center"
					alignItems="center"
				>
					<Grid item xs="auto">
						<img className={styles.img} src={Rppc1}></img>
					</Grid>
					<Grid item xs="auto">
						<img className={styles.img} src={Rppc2}></img>
					</Grid>
					<Grid item xs="auto">
						<img className={styles.img} src={Rppc3}></img>
					</Grid>
				</Grid>
			</Hidden>
			<Hidden smUp>
				<div className={styles.imgDiv}>
					<img className={styles.imgSmall} src={Rppc1}></img>
				</div>
			</Hidden>
		</div>
	);
}
