import React from "react";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import DiscordLogo from "../images/discord.svg";
import OpenSeaLogo from "../images/opensea.svg";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
	footerText: {
		marginRight: 10,
	},
}));

export default function Footer() {
	const styles = useStyles();
	return (
		<div>
			<AppBar position="static">
				<Container maxWidth="md">
					<Toolbar>
						<Typography
							className={styles.footerText}
							variant="body1"
						>
							© 2022 Retro Pixel Poker Club
						</Typography>
						<Link
							href="https://twitter.com/RetroPixelPoker"
							target="_blank"
						>
							<IconButton>
								<TwitterIcon />
							</IconButton>
						</Link>
						<Link
							href={"https://discord.com/invite/6kQKMXjhWz"}
							target="_blank"
						>
							<IconButton>
								<Icon>
									<img
										src={DiscordLogo}
										height={24}
										width={24}
									/>
								</Icon>
							</IconButton>
						</Link>
						<Link
							href={
								"https://opensea.io/collection/retro-pixel-poker-club"
							}
							target="_blank"
						>
							<IconButton>
								<Icon>
									<img
										src={OpenSeaLogo}
										height={24}
										width={24}
									/>
								</Icon>
							</IconButton>
						</Link>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
}
