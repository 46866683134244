import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "../components/NavBar";
import About from "../components/About";
import Tournaments from "../components/Tournaments";
import RoadMap from "../components/RoadMap";
import Collection from "../components/Collection";
import ImageBar from "../components/ImageBar";
import Footer from "../components/Footer";
import Container from "@material-ui/core/Container";
// import WebFont from "webfontloader";

const darkTheme = createTheme({
	palette: {
		type: "dark",
	},
	typography: {
		fontFamily: "VT323",
	},
});

export default function IndexPage() {
	// useEffect(() => {
	// 	WebFont.load({
	// 		google: {
	// 			families: ["VT323"],
	// 		},
	// 	});
	// }, []);
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<NavBar />
			<ImageBar />
			<Container maxWidth="lg">
				<About />
				<Tournaments />
				<Collection />
				<RoadMap />
			</Container>
			<Footer />
		</ThemeProvider>
	);
}
