import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
	sectionHeader: {},
	sectionText: {
		marginTop: 20,
		marginBottom: 20,
	},
	handRarityGrid: {
		align: "center",
	},
	handRarityItem: {
		border: "2px solid white",
		marginTop: 5,
	},
	gridText: {
		marginLeft: 30,
		marginTop: 5,
		marginBottom: 5,
	},
}));

const handRarities = [
	{ handRank: "High card", rarity: "905/2000", percentage: "45.25" },
	{ handRank: "Pair", rarity: "780/2000", percentage: "39" },
	{ handRank: "Two pair", rarity: "100/2000", percentage: "5" },
	{ handRank: "Three of a kind", rarity: "75/2000", percentage: "3.75" },
	{ handRank: "Straight", rarity: "50/2000", percentage: "2.5" },
	{ handRank: "Flush", rarity: "40/2000", percentage: "2" },
	{ handRank: "Full house", rarity: "25/2000", percentage: "1.25" },
	{ handRank: "Four of a kind", rarity: "13/2000", percentage: ".65" },
	{ handRank: "Straight flush", rarity: "8/2000", percentage: ".4" },
	{ handRank: "Royal flush", rarity: "4/2000", percentage: ".2" },
];

const prices = [
	{ handNumbers: "1-500", price: ".01" },
	{ handNumbers: "501-1000", price: ".025" },
	{ handNumbers: "1001-1500", price: ".05" },
	{ handNumbers: "1501-2000", price: ".1" },
];

export default function Collection() {
	const styles = useStyles();
	const retroPixelTwitterLink = (
		<Link href="https://twitter.com/RetroPixelPoker" target="_blank">
			@RetroPixelPoker
		</Link>
	);
	const retroOpenseaLink = (
		<Link
			href="https://opensea.io/collection/retro-pixel-poker-club"
			target="_blank"
		>
			here
		</Link>
	);
	return (
		<div id="collection">
			<Typography
				variant="h2"
				align="center"
				className={styles.sectionHeader}
			>
				The Collection
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				Check out the collection on OpenSea {retroOpenseaLink}. There
				will be 2021 unique items in this collection; 2000 random hands
				and 21 promo hands. The randomly generated hands have the
				following distribution:
			</Typography>

			{handRarities.map((item) => (
				<Grid container justifyContent="center">
					<Grid item xs={4} className={styles.handRarityItem}>
						<Typography variant="h6" className={styles.gridText}>
							{item.handRank}
						</Typography>
					</Grid>
					<Grid item xs={4} className={styles.handRarityItem}>
						<Typography variant="h6" className={styles.gridText}>
							{item.rarity}
						</Typography>
					</Grid>
				</Grid>
			))}
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				Price distribution:
			</Typography>
			{prices.map((item) => (
				<Grid container justifyContent="center">
					<Grid item xs={4} className={styles.handRarityItem}>
						<Typography variant="h6" className={styles.gridText}>
							Hands {item.handNumbers}
						</Typography>
					</Grid>
					<Grid item xs={4} className={styles.handRarityItem}>
						<Typography variant="h6" className={styles.gridText}>
							{item.price} ETH
						</Typography>
					</Grid>
				</Grid>
			))}
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				The last 21 hands will be a combination of giveaways, auctions,
				and contests. Make sure to join the Discord and follow us on
				Twitter {retroPixelTwitterLink} for updates! (price subject to
				change prior to release based on the price of Ethereum)
			</Typography>
		</div>
	);
}
