import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	sectionHeader: {
		marginBottom: 20,
	},
	subsectionHeader: {},
	sectionText: {
		marginBottom: 20,
	},
}));

export default function Roadmap() {
	const styles = useStyles();
	return (
		<div id="roadmap">
			<Typography
				variant="h2"
				align="center"
				className={styles.sectionHeader}
			>
				Roadmap
			</Typography>
			<Typography
				variant="h4"
				align="left"
				className={styles.subsectionHeader}
			>
				5% Sold
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				A promo NFT giveaway to the person who holds the most Retro
				Pixel Poker NFTs.
			</Typography>
			<Typography
				variant="h4"
				align="left"
				className={styles.subsectionHeader}
			>
				25% Sold
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				A raffle for a promo NFT. Each Retro Pixel Poker Club NFT grants
				one entry to the raffle.
			</Typography>
			<Typography
				variant="h4"
				align="left"
				className={styles.subsectionHeader}
			>
				100% Sold
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				RRPC Tournament series - more info to come on this soon.
			</Typography>
			<Typography
				variant="h4"
				align="left"
				className={styles.subsectionHeader}
			>
				100% Sold
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				Start development on our own Poker Website customized with NFT
				integration.
			</Typography>
		</div>
	);
}
