import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ChevronRight from "@material-ui/icons/ChevronRight";
import TwitterIcon from "@material-ui/icons/Twitter";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import DiscordLogo from "../images/discord.svg";
import OpenSeaLogo from "../images/opensea.svg";

const navigationLinks = [
	{ name: "About", href: "#about" },
	{ name: "Tournaments", href: "#tournaments" },
	{ name: "Collection", href: "#collection" },
	{ name: "Roadmap", href: "#roadmap" },
];

const socialItems = [
	{ icon: TwitterIcon, url: "https://twitter.com/RetroPixelPoker" },
];

const useStyles = makeStyles((theme) => ({
	link: {
		marginRight: 20,
	},
	navTitle: {
		marginRight: "auto",
	},
	appBar: {
		marginBottom: 40,
	},
}));

export default function NavBar() {
	const styles = useStyles();
	const [open, setOpen] = useState(false);
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="sticky" color="default" className={styles.appBar}>
				<Toolbar>
					<Typography
						className={styles.navTitle}
						variant="h5"
						component="div"
						sx={{ flexGrow: 1 }}
					>
						Retro Pixel Poker Club
					</Typography>
					<Hidden xsDown>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1 }}
						>
							{navigationLinks.map((item) => (
								<Link
									className={styles.link}
									color="textPrimary"
									href={item.href}
								>
									{item.name}
								</Link>
							))}
							{socialItems.map((item) => (
								<Link href={item.url} target="_blank">
									<IconButton>
										<item.icon />
									</IconButton>
								</Link>
							))}
							<Link
								href={"https://discord.com/invite/6kQKMXjhWz"}
								target="_blank"
							>
								<IconButton>
									<Icon>
										<img
											src={DiscordLogo}
											height={24}
											width={24}
										/>
									</Icon>
								</IconButton>
							</Link>
							<Link
								href={
									"https://opensea.io/collection/retro-pixel-poker-club"
								}
								target="_blank"
							>
								<IconButton>
									<Icon>
										<img
											src={OpenSeaLogo}
											height={24}
											width={24}
										/>
									</Icon>
								</IconButton>
							</Link>
						</Typography>
					</Hidden>
					<Hidden smUp>
						<IconButton>
							<MenuIcon onClick={() => setOpen(true)} />
						</IconButton>
					</Hidden>
				</Toolbar>
				<SwipeableDrawer
					anchor="right"
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
				>
					<div>
						<IconButton>
							<ChevronRight onClick={() => setOpen(false)} />
						</IconButton>
					</div>
					<Divider />
					<List>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1 }}
						>
							{navigationLinks.map((item) => (
								<ListItem>
									<Link
										className={styles.link}
										color="textPrimary"
										href={item.href}
									>
										{item.name}
									</Link>
								</ListItem>
							))}
							{socialItems.map((item) => (
								<Link href={item.url} target="_blank">
									<IconButton>
										<item.icon />
									</IconButton>
								</Link>
							))}
							<Link
								href={"https://discord.com/invite/6kQKMXjhWz"}
								target="_blank"
							>
								<IconButton>
									<Icon>
										<img
											src={DiscordLogo}
											height={24}
											width={24}
										/>
									</Icon>
								</IconButton>
							</Link>
							<Link
								href={
									"https://opensea.io/collection/retro-pixel-poker-club"
								}
								target="_blank"
							>
								<IconButton>
									<Icon>
										<img
											src={OpenSeaLogo}
											height={24}
											width={24}
										/>
									</Icon>
								</IconButton>
							</Link>
						</Typography>
					</List>
				</SwipeableDrawer>
			</AppBar>
		</Box>
	);
}
