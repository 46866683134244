import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Spoils from "../images/spoils.png";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
	sectionHeader: {
		marginBottom: 20,
	},
	sectionText: {
		marginTop: 20,
		marginBottom: 20,
	},
	img: {
		maxWidth: 600,
	},
	imgSmall: {
		maxWidth: 300,
	},
	imgDiv: {
		textAlign: "center",
	},
}));

export default function Tournaments() {
	const styles = useStyles();
	const pixelSpoilsLink = (
		<Link href="https://opensea.io/collection/pixel-spoils" target="_blank">
			Pixel Spoils
		</Link>
	);
	return (
		<div id="tournaments">
			<Typography
				variant="h2"
				align="center"
				className={styles.sectionHeader}
			>
				Tournaments
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				We will be holding weekly tournaments with NFT prizes based on
				the number of entrants (1 prize minimum). You can win an item
				from the {pixelSpoilsLink} collection!
			</Typography>
			<Hidden xsDown>
				<div className={styles.imgDiv}>
					<img className={styles.img} src={Spoils}></img>
				</div>
			</Hidden>
			<Hidden smUp>
				<div className={styles.imgDiv}>
					<img className={styles.imgSmall} src={Spoils}></img>
				</div>
			</Hidden>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				If own a Retro Pixel Poker Club NFT, join the discord and type
				"!join" to verify your ownership. We'll be in contact with you
				to get you set up for the next tournament!
			</Typography>
		</div>
	);
}
