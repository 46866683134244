import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	sectionHeader: {
		marginBottom: 20,
	},
	sectionText: {
		marginTop: 20,
		marginBottom: 20,
	},
}));

export default function About() {
	const styles = useStyles();
	return (
		<div id="about">
			<Typography
				variant="h2"
				align="center"
				className={styles.sectionHeader}
			>
				Combining Poker and NFTs
			</Typography>
			<Typography
				variant="h6"
				component="p"
				className={styles.sectionText}
			>
				Welcome to Retro Pixel Poker Club, a community of gamers, poker
				players and NFT lovers. Owning a Retro Pixel Poker Club NFT
				gives you access to play in our weekly tournaments where you can
				win NFT prizes. Owning a Retro Pixel Poker Club NFT will also
				allow you to vote on decisions including choosing prizes and
				tournaments days/times.
			</Typography>
		</div>
	);
}
